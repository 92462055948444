(function ($) {
    $('a.fbx-link').on('click', function(e){
        e.preventDefault();
    });
  $(document).ready(function () {
    if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      navigator.appVersion.indexOf("Trident/") > -1
    ) {
      $("body").addClass("ie");
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      $("body").addClass("touch-device");
    }

    stickyBookingActions();
    menuActions();
    vimeoPlayer();
    initSliders();
    formActions();
    promos();
    datepicker();
    filter_accommodation();
    filterEvents();
    select_category();
    generalModal();
    initializeBookBothHotelsOfferModal();

    if (document.querySelector("#top-notification")) {
      openAnnoucementBar();
      $(".close-announcement-bar").on("click", function () {
        closeAnnouncementBar();
      });
    }
    if(document.querySelector(".navbar-toggler") !== null){
      document
        .querySelector(".navbar-toggler")
        .addEventListener("click", function (evt) {
          setTimeout(function () {
            jQuery(".mCustomScrollbar").mCustomScrollbar("update");
          }, 300);
        });
      }

    function initializeBookBothHotelsOfferModal() {
        if (typeof $.cookie !== "function") return;
        if ($.cookie("bothoffer") != 1) {
            if ($("#book-both-offer").length > 0) {
                setTimeout(function () {
                $("#book-both-offer").modal("show");
                }, 0.5 * 60 * 1000);
            }
        }

      $("#book-both-offer").on("click", function (evt) {
        if (
          evt.target.classList.contains("close") ||
          evt.target.classList.contains("close-symbol")
        ) {
          console.log("click close");
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + 24 * 36000;
          now.setTime(expireTime);
          $.cookie("bothoffer", "1", { expires: 1, path: "/" });
        //   document.cookie = "bothoffer=closed;expires=" + now.toUTCString();
        }
      });
    }

    $(".wpcf7-select.time").on(
      "show.bs.select",
      function (e, clickedIndex, isSelected, previousValue) {
        //console.log("test");
        $(this).find(".inner").mCustomScrollbar({
          theme: "dark",
        });
      }
    );

    if ($("#inner-blog")) {
      if (!$("section p img").hasClass("img-fluid")) {
        $("section p img").addClass("img-fluid");
      }
    }

    // //mobile bar
    // if( ($(".hotels").length>0) ) {
    //     // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    //     var vh = window.innerHeight * 0.01; // Then we set the value in the --vh custom property to the root of the document

    //     document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
    //     introHotelLink();
    // }
  });

  //mobile bar
  window.addEventListener("resize", function () {
    // We execute the same script as before
    if ($(".hotels").length > 0 && $(window).width() < 768) {
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", "".concat(vh, "px"));
    }
  });

  $(window).load(function () {
    stickyBooking();
    promoBoxActions();
    bookTopMenuVisibility();
    //do not remove the above timeout-after updates at homepage
    //scrollbar stopped working-this fixes the problem
    setTimeout(function () {
      if ($(".scrollable-area").length > 0) {
        var scrArea = $(".scrollable-area");
        scrArea
          .find(".mCSB_scrollTools")
          .addClass("mCSB_1_scrollbar mCS-dark mCSB_scrollTools_vertical");
      }
    }, 500);

    if ($("body").hasClass("touch-device")) {
      $(".scrollable-area").mCustomScrollbar("destroy");
    }
  });

  var runit;
  window.onresize = function () {
    clearTimeout(runit);
    runit = setTimeout(resizedw, 100);
  };

  function resizedw() {
    if ($(".booking-bar").length > 0) {
      var navWrap = $("#booking-wrap"),
        nav = $(".booking-bar"),
        startPosition = navWrap.offset().top,
        stopPosition = $("footer").offset().top - nav.outerHeight();
    }
    bookTopMenuVisibility();
  }

  function promoBoxActions() {
    //console.log(Cookies.get());
    if (typeof $.cookie !== "function") return;

    if ($.cookie("promo-box") != 1) {
      if ($(".promo-box").length > 0) {
        $(".promo-box").removeClass("offscreen");
        $(".promo-close").click(function () {
          $(".promo-box").addClass("offscreen");
          $.cookie("promo-box", "1", { expires: 1, path: "/" });
        });
      }
    }
  }

  function generalModal() {
    //console.log(Cookies.get());
    if (typeof $.cookie !== "function") return;

    if ($.cookie("general-modal") != 1) {
      $("#general-modal").modal("show");
      $.cookie("general-modal", "1", { expires: 30, path: "/" });
    }
  }

  function fetchVideo(id){
    var url = 'https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/'+id;
    fetch(url)
      .then((response) => response.json())
      .then(data => {
          var padding = data.height / data.width * 100
          var dimensions = Math.round((padding + Number.EPSILON) * 100) / 100;

          $('#vimeo-player').css('padding', dimensions + '% 0 0');
      })
      .catch(error => {
          console.error('error fetching video');
      });
      
  }

  function vimeoPlayer(){
    if($('#vimeo-player').length){
        const videoWrapperEl = 'vimeo-player';
        const vimeoPlayerWrapper = document.querySelector("#vimeo-player"); 
        const vimeoId = vimeoPlayerWrapper.dataset.id;

        const playBtn = $('.play-vimeo-btn');
        const muteBtn = $('.volume-vimeo-btn');
        var isPlaying = true;
        var isMuted = true;
  
        fetchVideo(vimeoId);

        // config passed to Vimeo API 
        const viemoOptions = {
            id: vimeoId,
            loop: true,
            muted: true,
            autoplay: true,
            autopause: false,
            controls: false,
        }

        // reference html elements
        const player = new Vimeo.Player(videoWrapperEl, viemoOptions);

        playBtn.on('click',()=>{
            if(isPlaying){
                player.pause();
                isPlaying = false;
                playBtn.removeClass('pause');
                playBtn.addClass('play');
            }else{
                player.play();
                isPlaying = true;
                playBtn.removeClass('play');
                playBtn.addClass('pause');
            }
        });

        muteBtn.on('click',()=>{
            if(isMuted){
                player.setVolume(1);
                isMuted = false;
                muteBtn.removeClass('mute');
                muteBtn.addClass('loud');
            }else{
                player.setVolume(0);
                isMuted = true;
                muteBtn.removeClass('loud');
                muteBtn.addClass('mute');
            }
        });
    }
    
}

//   function heroPlayer() {
//     if ($(".player-container").length > 0) {
//       var myPlayer;

//       myPlayer = $("#P1").YTPlayer();
//       myPlayer.on("YTPData", function (e) {
//         $(".dida").html(e.prop.title + "<br>@" + e.prop.channelTitle);
//       });

//       //myPlayer.on('YTPPlay', function(){ console.log('playing'); });
//     }

//     $("#header-img").click(function () {
//       if ($(this).find(".player-container").hasClass("video-plays")) {
//         $(this).find(".player-container").removeClass("video-plays");
//         $(this).find(".player-container").css("z-index", "-1");
//         $("#P1").YTPStop();
//       } else {
//         $(this).find(".player-container").addClass("video-plays");
//         $(this).find(".player-container").css("z-index", "0");
//         $("#P1").YTPPlay();
//       }
//     });
//   }

  function filterEvents() {
    $(".filters-title").click(function () {
      if ($(".filters-wrapper").hasClass("open")) {
        $(".filters-wrapper").removeClass("open");
      } else {
        $(".filters-wrapper").addClass("open");
      }
    });

    $(window).click(function () {
      if ($(".filters-wrapper").hasClass("open")) {
        $(".filters-wrapper").removeClass("open");
      }
    });

    $(".filters-wrapper,a").click(function (event) {
      if ($(".filters-wrapper").length > 0) {
        event.stopPropagation();
      }
    });
  }

  function menuActions() {
    $(".navbar-collapse").on("show.bs.collapse", function () {
      $(".nav-icon").addClass("open");
    });

    $(".navbar-collapse").on("shown.bs.collapse", function () {
      $("html").addClass("overflow-hidden h-100");
      $("body").addClass("overflow-hidden h-100");
      $(".nav-icon").addClass("open");
      $(".navbar-toggler").addClass("toggler-fixed");
      $(window).trigger("resize");
    });
    $(".navbar-collapse").on("hide.bs.collapse", function () {
      $("html").removeClass("overflow-hidden h-100");
      $("body").removeClass("overflow-hidden h-100");
      $(".navbar-toggler").removeClass("toggler-fixed");
      $(".nav-icon").removeClass("open");
    });

    menuEvents();
  }

  function menuEvents() {
    if ($("body").hasClass("touch-device")) {
      $(".nav-item .sub-menu-arrow").click(function () {
        $this = $(this);
        $target_submenu = $this.parent().find(".sub-menu");
        $target_navitem = $this.parent(".nav-item");

        if ($target_navitem.hasClass("opened")) {
          $target_navitem.removeClass("opened");
        } else {
          $(".nav-item").not($target_navitem).removeClass("opened");
          $target_navitem.addClass("opened");
        }
      });
    }

    if ($(window).width() > 767) {
      $(".nav-item").mouseenter(function () {
        //$(this).find(".sub-menu-arrow").fadeIn("fast").css("display","inline-flex");
        $(this).find(".sub-menu").fadeIn("fast");
        //$(".scrollable-area").mCustomScrollbar("update");
      });

      $(".nav-item").mouseleave(function () {
        if (!$(this).hasClass("opened")) {
          //$(this).find(".sub-menu-arrow").fadeOut("fast");
          $(this).find(".sub-menu").fadeOut("fast");
          //$(".scrollable-area").mCustomScrollbar("update");
        }
      });
    }
  }

  function initSliders() {
    if ($(".restaurants-slider").length > 0) {
      $(".restaurants-slider").slick({
        dots: true,
        arrows: true,
        autoplay: false,
        adaptiveHeight: true,
        appendDots: $(".restaurants-navigation"),
        customPaging: function (slider, i) {
          var thumb = $(slider.$slides[i]).data();
          return "<a>[[num]]</a>".replace(
            "[[num]]",
            i < 9 ? "0".concat(i + 1) : i
          );
        },
      });
    }

    if ($(".posts-slider").length > 0) {
      $(".posts-slider").slick({
        dots: true,
        arrows: false,
        autoplay: false,
        adaptiveHeight: true,
      });
    }

    if ($(".activities-slider").length > 0) {
      $(".activities-slider").slick({
        dots: true,
        arrows: true,
        autoplay: false,
        adaptiveHeight: true,
        appendDots: $(".activities-navigation"),
        customPaging: function (slider, i) {
          var thumb = $(slider.$slides[i]).data();
          return "<a>[[num]]</a>".replace(
            "[[num]]",
            i < 9 ? "0".concat(i + 1) : i
          );
        },
      });
    }
    if ($(".intro-slider").length > 0) {
      if ($(".intro-slider").hasClass("slick-initialized")) {
        $("body").addClass("overflow-hidden");
      } else {
        $("body").removeClass("overflow-hidden");
      }
    }

    if ($(".accommodation-slider").length > 0) {
      window.accommodationSlider = $(".accommodation-slider").slick({
        dots: true,
        autoplay: false,
        arrows: true,
        adaptiveHeight: true,
        appendDots: $(".accommodation-navigation"),
        // prevArrow: $('.acc-prev-arrow'),
        // nextArrow: $('.acc-next-arrow'),
        customPaging: function (slider, i) {
          var thumb = $(slider.$slides[i]).data();
          return "<a>[[num]]</a>".replace(
            "[[num]]",
            i < 9 ? "0".concat(i + 1) : i
          );
        },
      });
    }

    if ($(".experiences-slider").length > 0) {
      $(".experiences-slider").slick({
        dots: true,
        arrows: false,
        autoplay: false,
        adaptiveHeight: true,
        appendDots: $(".dots-container"),
        customPaging: function (slider, i) {
          var thumb = $(slider.$slides[i]).data();
          return "<a>[[num]]</a>".replace(
            "[[num]]",
            i < 9 ? "0".concat(i + 1) : i
          );
        },
      });
    }

    $('.gallery-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('a.foobox').off('click');
        $('a.fbx-link').on('click', function(e){
            e.preventDefault();
        });
    });

    $('.gallery-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        FOOBOX.init();
        $('a.fbx-link').on('click', function(e){
            e.preventDefault();
        });
        //ios no scroll on modal open
        $('.fbx-instance') .on('foobox.beforeShow', function(e) {  
            $('html').addClass('freeze').css({
                marginTop: -previousScrollY,
            });
        });
    
        //ios no scroll on modal open
        $('.fbx-instance') .on('foobox.close', function(e) {  
            $('html').removeClass('freeze').css({
                marginTop: 0,
            });
            window.scrollTo({top: previousScrollY, behavior: 'instant',});
        });
    });

    // Reinitialise foobox after slick breakpoints
    $('.gallery-slider').on('breakpoint', function(event, slick, breakpoint) {
        FOOBOX.init();

        //ios no scroll on modal open
        $('.fbx-instance') .on('foobox.beforeShow', function(e) {  
            previousScrollY = window.scrollY;
            $('html').addClass('freeze').css({
                marginTop: -previousScrollY,
            });
        });

        //ios no scroll on modal open
        $('.fbx-instance') .on('foobox.close', function(e) {  
            $('html').removeClass('freeze').css({
                marginTop: 0,
            });
            window.scrollTo({top: previousScrollY, behavior: 'instant',});
        });
    });

    if ($(".gallery-slider").length > 0) {
      $(".gallery-slider").slick({
        dots: false,
        slidesToShow: 2.5,
        infinite: false,
        autoplay: false,
        // adaptiveHeight: true,
        // centerPadding: '0px',
        prevArrow: $(".prev-arrow"),
        nextArrow: $(".next-arrow"),
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1.5,
            },
          },
          {
            breakpoint: 576,
            settings: {
              dots: false,
              slidesToShow: 1,
              prevArrow: $(".prev-arrow-mobile"),
              nextArrow: $(".next-arrow-mobile"),
              // appendArrows: $('.arrows-container.mobile'),
              // appendDots: $(".gallery-navigation"),
              // customPaging: function (slider, i) {
              //     var thumb = $(slider.$slides[i]).data();
              //     // return '<a>[[num]]</a>'.replace('[[num]]', i < 9 ? '0'.concat(i+1) : i);
              //     return '<a>[[num]]</a>'.replace('[[num]]', i < 9 ? '0'.concat(i+1) : i);
              // }
            },
          },
        ],
      });
      // Mandatory here to play on mobile devices
      FOOBOX.init();
    }

    //ios no scroll on modal open
    var previousScrollY = 0;
    $('.fbx-instance') .on('foobox.beforeShow', function(e) {  
        previousScrollY = window.scrollY;
        $('html').addClass('freeze').css({
            marginTop: -previousScrollY,
        });
    });

    //ios no scroll on modal open
    $('.fbx-instance') .on('foobox.close', function(e) {  
        $('html').removeClass('freeze').css({
            marginTop: 0,
        });
        window.scrollTo({top: previousScrollY, behavior: 'instant',});
    });

    if ($(".promo-slider").length > 0) {
      $(".promo-slider").slick({
        dots: true,
        arrows: false,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        responsive: [
          {
            breakpoint: 1550,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    }

    $(".collapse").on("show.bs.collapse", function () {
      $(this).siblings(".article-titles").addClass("open");
    });
    $(".collapse").on("hide.bs.collapse", function () {
      $(this).siblings(".article-titles").removeClass("open");
    });
  }

  var slickset = false;

  //Booking scripts must run after menu;
  function stickyBooking() {
    if ($(".booking-bar").length > 0) {
      var navWrap = $("#booking-wrap");
      var nav = $(".booking-bar");
      //var bookButton=$('.booking-bar').find(".btn");
      var bookButton = $(".book-btn");
      var startPosition = navWrap.offset().top;
      var topMenu = $(".top-menu");
      var logoImg = $(".small-logo img");
      //stop sticky when reaching footer
      //var stopPosition = $('footer').offset().top - nav.outerHeight();

      $(document).scroll(function () {
        if (slickset == false) {
          $(".slick-slider").each(function () {
            $(this).slick("setPosition");
          });
          slickset == true;
        }

        bookTopMenuVisibility();

        startPosition = navWrap.offset().top;
        var y = $(window).scrollTop();

        // if (y > 60) {
        //     $(".menu-logo").addClass("hidden");
        //     $(".scroll-down").fadeOut(100);
        // }else {
        //     $(".menu-logo").removeClass("hidden");
        //     $(".scroll-down").fadeIn(100);
        // }

        if (y > startPosition) {
          nav.addClass("sticky invisible");
          topMenu.addClass("has-shadow");
          topMenu.addClass("sticky-top-menu");
          bookButton.addClass("sticky-btn");

          // console.log(logoImg.attr('src'));
          // if(logoImg.attr('src').indexOf('icon_kivotos_white.svg') > -1){
          //     logoImg.attr('src', logoImg.attr('src').replace('icon_kivotos_white.svg', 'icon_kivotos.svg'))
          // }

          if ($(".book-margin").length == 0) {
            $('<div class="book-margin"></div>').insertAfter(nav);
            $(".book-margin").css("height", nav.outerHeight());
          }
        } else {
          nav.removeClass("sticky invisible");
          topMenu.removeClass("has-shadow");
          topMenu.removeClass("sticky-top-menu");
          $(".close-booking").hide();
          bookButton.removeClass("sticky-btn");

          // if(logoImg.attr('src').indexOf('icon_kivotos.svg') > -1){
          //     logoImg.attr('src', logoImg.attr('src').replace('icon_kivotos.svg', 'icon_kivotos_white.svg'))
          // }

          $(".book-margin").remove();
        }
      });
    }
  }

  function stickyBookingActions() {
    if ($("#booking-wrap").length > 0) {
      $("#adults-wrapper .button, #kids-wrapper .button").on(
        "click",
        function () {
          var $button = $(this);
          var oldValue = $button.parent().find("input").val();

          if ($button.hasClass("inc")) {
            var newVal = parseFloat(oldValue) + 1;
          } else {
            // Don't allow decrementing below zero
            if (oldValue > 0) {
              var newVal = parseFloat(oldValue) - 1;
            } else {
              newVal = 0;
            }
          }
          $button.parent().find("input").val(newVal);
        }
      );
    }
    $(".book-btn").click(function (e) {
      if ($(this).hasClass("sticky-btn") && !$(this).hasClass("clicked")) {
        $(".booking-bar").removeClass("invisible");
        $(this).addClass("clicked");
        $(".close-booking").show();
      } else {
        $(this).removeClass("clicked");
        $("#booking-form").submit();
      }
    });

    $(".close-booking").click(function () {
      $(".book-btn").removeClass("clicked");
      $(".booking-bar").addClass("invisible");
    });
  }

  /**
   *
   */
  function formActions() {
    document.addEventListener(
      "wpcf7invalid",
      function (event) {
        $(".form-field").each(function () {
          if (!$(this).find(".wpcf7-not-valid-tip").length > 0) {
            $(this).removeClass("invalid");
          }
        });
        $(".wpcf7-not-valid-tip").each(function () {
          $selector = $(this).closest(".form-field");
          $selector.addClass("invalid");
          $(this).detach().appendTo($selector);
        });
      },
      false
    );

    document.addEventListener(
      "wpcf7mailsent",
      function (event) {
        $(".form-field").each(function () {
          $(this).removeClass("invalid");
        });

        var formId = event.detail.contactFormId;
        if (formId != "442") {
          $("#thanks-modal .modal-text").html(event.detail.apiResponse.message);
          $("#thanks-modal").modal("show");
        }

        setTimeout(function () {
          $(".pretty input.selected").each(function () {
            $(this).prop("checked", true);
          });
        }, 100);
      },
      false
    );

    $(".pretty input.selected").each(function () {
      $(this).prop("checked", true);
    });

    $(".pretty input").on("click", function (e) {
      if (!$(this).hasClass("selected")) {
        $(this).addClass("selected");
        $(".wpcf7-submit").removeAttr("disabled", "disabled");
      } else {
        $(this).removeClass("selected");
        $(".wpcf7-submit").attr("disabled", "disabled");
      }
    });

    $(".state label a").click(function (event) {
      event.stopPropagation();
      event.preventDefault();

      window.open($(this).attr("href"), $(this).attr("target"));
      return false;
    });
  }

  function promos() {
    if (!$("body").hasClass("touch-device")) {
      $("#promos .slick-slide").mouseenter(function () {
        $this = $(this);

        $this.find(".title").fadeOut(100, function () {
          $this.find(".title").fadeIn(300);
          $this.find(".subtitle").fadeIn(300);
          $this.find(".excerpt").fadeIn(300);
          $this.find(".btn").fadeIn(300).css("display", "block");
        });
      });

      $("#promos .slick-slide").mouseleave(function () {
        $this = $(this);

        $this.find(".title").fadeOut(100);
        $this.find(".subtitle").fadeOut(100);
        $this.find(".excerpt").fadeOut(100);
        $this.find(".btn").fadeOut(100);

        setTimeout(function () {
          $("#promos .slick-slide").each(function () {
            $(this).find(".title").fadeIn(300);
          });
        }, 100);
      });
    }
  }

  function datepicker() {
    if (!($("#check-in-picker").length && $("#check-out-picker").length))
      return;

    if (!$("body").hasClass("touch-device")) {
      $("#date").datepicker({
        format: "dd/mm/yyyy",
        pickerPosition: "bottom-left",
        container: "[data-name='date']",
        minViewMode: "days",
        maxViewMode: "days",
        startView: "days",
        todayHighlight: true,
        autoclose: true,
        templates: {
          leftArrow: '<span class="icon-arrow-left"></span>',
          rightArrow: '<span class="icon-arrow-right"></span>',
        },
      });

      var year = new Date().getFullYear();

      var start = new Date($("#check-in-picker").data("startday"));
      var end = new Date($("#check-out-picker").data("endday"));

      let startArr = $("#check-in-picker").data("startday").split("/");
      let endArr = $("#check-out-picker").data("endday").split("/");

      let startDate = new Date(
        startArr[2],
        parseInt(startArr[0]) - 1,
        startArr[1]
      );
      let endDate = new Date(endArr[2], parseInt(endArr[0]) - 1, endArr[1]);
      let date = new Date();

      if (+date >= +startDate && date <= +endDate) {
        start = date;
      }

      $("#check-in-picker")
        .datepicker({
          format: "dd/mm/yyyy",
          pickerPosition: "bottom-left",
          container: "#check-in-wrapper",
          minViewMode: "days",
          maxViewMode: "days",
          startView: "days",
          todayHighlight: true,
          startDate: start,
          endDate: end,
          templates: {
            leftArrow: '<span class="icon-arrow-left"></span>',
            rightArrow: '<span class="icon-arrow-right"></span>',
          },
        })
        .on("changeDate", function () {
          $("#check-out-picker").val("");
          var checkinValue = $(this).datepicker("getDate");

          if (checkinValue) {
            var nextDayDate = new Date(
              checkinValue.getFullYear(),
              checkinValue.getMonth(),
              checkinValue.getDate() + 1
            );

            $("#check-out-picker").datepicker("setStartDate", nextDayDate);

            //YYYY-MM-DD
            var dateAr = $(this).val().split("/");
            var newDate = dateAr[2] + "-" + dateAr[1] + "-" + dateAr[0];

            $("#checkin").val(newDate);

            $(this).datepicker("hide");
          }
        });

      $("#check-out-picker")
        .datepicker({
          format: "dd/mm/yyyy",
          pickerPosition: "bottom-left",
          container: "#check-out-wrapper",
          minViewMode: "days",
          maxViewMode: "days",
          startView: "days",
          todayHighlight: true,
          startDate: start,
          endDate: end,
          templates: {
            leftArrow: '<span class="icon-arrow-left"></span>',
            rightArrow: '<span class="icon-arrow-right"></span>',
          },
        })
        .on("changeDate", function () {
          //YYYY-MM-DD
          var dateAr = $(this).val().split("/");
          var newDate = dateAr[2] + "-" + dateAr[1] + "-" + dateAr[0];
          $("#checkout").val(newDate);
          $(this).datepicker("hide");
        });

      $("#check-in-picker").datepicker("setDate", start);
      try {
        $(".wpcf7-select").selectpicker();
      } catch (e) {
      }
    } else {
      if ($(".datepicker").length > 0) {
        $("#date").datepicker("destroy");
      }

      $("#date").attr("type", "date");

      try {
        $(".wpcf7-select").selectpicker("destroy");
      } catch (e) {
      }
    }
  }

  function filter_accommodation() {
    if ($(".filters").length > 0) {
      $(".filters .box-border").click(function () {
        if ($(this).hasClass("selected")) {
          $unfilter_id = $(this).attr("id");
          $(this).removeClass("selected");
          $(this).find("input").prop("checked", false);

          if (
            $unfilter_id == "sea" ||
            $unfilter_id == "garden" ||
            $unfilter_id == "sunset"
          ) {
            $("article[data-view=" + $unfilter_id + "]").removeClass(
              "filtered"
            );
          } else if ($unfilter_id == "pool") {
            $("article[data-pool='1']").removeClass("filtered");
          } else if ($unfilter_id == "jacuzzi") {
            $("article[data-jacuzzi='1']").removeClass("filtered");
          }
        } else {
          $(this).addClass("selected");
          $(this).find("input").prop("checked", true);
          $(".post-type-list").addClass("has-filters");
        }

        $attributes = "";
        $(".box-border.selected").each(function () {
          $filter_id = $(this).attr("id");

          if (
            $filter_id == "sea" ||
            $filter_id == "garden" ||
            $filter_id == "sunset"
          ) {
            $attributes += '[data-view="' + $filter_id + '"]';
          } else if ($filter_id == "pool") {
            $attributes += '[data-pool="1"]';
          } else if ($filter_id == "jacuzzi") {
            $attributes += '[data-jacuzzi="1"]';
          }
          if ($("article" + $attributes + "").length > 0) {
            $("article").removeClass("filtered");
            $("article" + $attributes + "").addClass("filtered");
          } else {
            $("article").removeClass("filtered");
          }
        });

        showMatchedElements();
      });
    }
  }

  function select_category() {
    if ($(".categories").length > 0) {
      $(".category").click(applyFilters);
    }
    let hash = window.location.hash;
    if (
      hash.length > 0 &&
      ["all", "suite", "villa"].indexOf(hash.replace("#", "")) > -1
    ) {
      $(hash).addClass("selected");
      applyFilters($(hash));
    }

    function applyFilters(evt) {
      console.log($(this).attr("id"));
      $(".category").removeClass("selected");

      if ($(this).attr("id") !== undefined) {
        $(this).addClass("selected");
      } else {
        // console.log($('#' + evt.id))
        evt.addClass("selected");
      }

      $(".post-type-list").addClass("has-filters");

      $filter_id = $(".category.selected").attr("id");
      $("article").removeClass("categorized");
      if (
        $filter_id == "suite" ||
        $filter_id == "villa" ||
        $filter_id == "restaurant" ||
        $filter_id == "bar" ||
        $filter_id == "breakfast"
      ) {
        $("article[data-type=" + $filter_id + "]").addClass("categorized");
      } else if ($filter_id == "all") {
        $("article[data-type='suite']").removeClass("categorized");
        $("article[data-type='villa']").removeClass("categorized");
        $("article[data-type='restaurant']").removeClass("categorized");
        $("article[data-type='bar']").removeClass("categorized");
        $("article[data-type='breakfast']").removeClass("categorized");
      }

      showMatchedElements();
    }
  }

  function instagramFeed() {
    if ($("#instagram-feed .wdi_feed_wrapper").length > 0) {
      $("#instagram-feed .wdi_feed_wrapper")
        .not(".slick-initialized")
        .slick({
          dots: false,
          slidesToShow: 1,
          autoplay: false,
          slidesToScroll: 1,
          prevArrow: $(".prev-arrow"),
          nextArrow: $(".next-arrow"),
        });
    }
  }

  function showMatchedElements() {
    if ($(".categorized").length > 0) {
      if ($(".categorized.filtered").length > 0) {
        $(".no-results").hide();
        $("article").hide().removeClass("even");
        $(".categorized.filtered").filter(":odd").addClass("even");
        $(".categorized.filtered").fadeIn("slow");
      } else if (
        $(".box-border.selected").length > 0 &&
        $(".categorized.filtered").length == 0
      ) {
        $(".no-results").fadeIn("slow");
        $("article").fadeOut("slow");
      } else {
        $(".no-results").hide();
        $("article").hide().removeClass("even");
        $(".categorized").filter(":odd").addClass("even");
        $(".categorized").fadeIn("slow");
      }
    } else {
      if ($(".filtered").length > 0) {
        $(".no-results").hide();
        $("article").hide().removeClass("even");
        $(".filtered").filter(":odd").addClass("even");
        $(".filtered").fadeIn("slow");
      } else {
        if ($(".box-border.selected").length > 0) {
          $(".no-results").fadeIn("slow");
          $("article").fadeOut("slow");
        } else {
          $(".no-results").hide();
          $("article").hide().removeClass("even");
          $("article").filter(":odd").addClass("even");
          $("article").fadeIn("slow");
        }
      }
    }
  }

  function bookTopMenuVisibility() {
    if ($(".booking-bar").length > 0 && $(window).outerWidth() > 1263) {
      // console.log("test");
      var element = $(".booking-bar");
      var top_of_element = element.offset().top;
      var bottom_of_element = top_of_element + element.outerHeight();
      var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
      var top_of_screen = $(window).scrollTop();

      // if ((bottom_of_screen > top_of_element + 25) && (top_of_screen < bottom_of_element)){
      // if ($(window).scrollTop() > 1228){
      if ($(window).scrollTop() / $(document).height() > 0.1) {
        $("#book-topmenu").fadeOut(200);
      } else {
        $("#book-topmenu").fadeIn(250);
      }
    }
  }

  function setCookie(key, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
    document.cookie = key + "=" + value + ";expires=" + expires.toUTCString();
  }

  function getCookie(key) {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  }

  function openAnnoucementBar() {
    if (document.cookie.indexOf("covid=closed") === -1) {
      document.querySelector("body").classList.add("covid-notification-open");
      document.querySelector("#top-notification").style.height = "45px";
      document.querySelector(".top-menu").style.marginTop = "45px";
    }
  }

  function closeAnnouncementBar() {
    document.querySelector("body").classList.remove("covid-notification-open");
    document.querySelector("#top-notification").style.height = "0px";
    document.querySelector(".top-menu").style.marginTop = "0px";

    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 24 * 36000;
    now.setTime(expireTime);

    document.cookie = "covid=closed;expires=" + now.toUTCString();
  }

})(jQuery);